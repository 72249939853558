<template>
  <div v-b-tooltip.hover.right="'Perfil de usuario'">
    <a
      href="#"
      class="btn btn-icon btn-clean btn-lg w-40px h-40px"
      id="kt_quick_user_toggle"
      data-toggle="tooltip"
      data-placement="right"
      data-container="body"
      data-boundary="window"
      title=""
      data-original-title="Perfil de usuario"
    >
      <span class="symbol symbol-30 symbol-lg-40">
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/General/User.svg" />
          <!--end::Svg Icon-->
        </span>
        <!--<span class="symbol-label font-size-h5 font-weight-bold">S</span>-->
      </span>
    </a>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-left p-8"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          Perfil de usuario
          <small class="text-muted font-size-sm ml-2">12 messages</small>
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">            
            <img class="img-fluid" :src="user.seller.logo" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ user.seller.name }}
            </a>
            <div class="text-muted mt-1">Restaurante</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ user.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>            
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <h5 class="mb-5">Acciones rápidas</h5>
          <!--begin::Item-->          
          <div class="d-flex align-center">
            <div class="symbol symbol-40 mr-3">
              <div class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-success">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    src="media/svg/icons/General/Notification2.svg"
                  />
                  <!--end::Svg Icon-->
                </span>
              </div>
            </div>
            <div class="navi-text" style="flex-grow:1;">
              <div class="font-weight-bold">Disponible</div>
              <div class="text-muted">
                Activa para aceptar pedidos                
              </div>
            </div>
            <div style="flex-grow:2; display: flex; justify-content: flex-end;">
              <v-switch :input-value="acceptOrders" @change="onAcceptOrdersChange"/>
            </div>
          </div>          
          <!--end:Item-->
          <!--begin::Item-->
          <!-- <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-warning"> -->
                    <!--begin::Svg Icon-->
                    <!-- <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" /> -->
                    <!--end::Svg Icon-->
                  <!-- </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Messages</div>
                <div class="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </router-link> -->
          <!--end:Item-->
          <!--begin::Item-->
          <!-- <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger"> -->
                    <!--begin::Svg Icon-->
                    <!-- <inline-svg src="media/svg/icons/Files/Selected-file.svg" /> -->
                    <!--end::Svg Icon-->
                  <!-- </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Activities</div>
                <div class="text-muted">Logs and notifications</div>
              </div>
            </div>
          </router-link> -->
          <!--end:Item-->
          <!--begin::Item-->
          <!-- <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary"> -->
                    <!--begin::Svg Icon-->
                    <!-- <inline-svg
                      src="media/svg/icons/Communication/Mail-opened.svg"
                    /> -->
                    <!--end::Svg Icon-->
                  <!-- </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Tasks</div>
                <div class="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </router-link> -->
          <!--end:Item-->
        </div>
        <!--end::Nav-->
        <div class="separator separator-dashed my-7"></div>
        <!--begin::Notifications-->
        <div>
          <!--begin:Heading-->
          <!-- <h5 class="mb-5">Recent Notifications</h5> -->
          <!--end:Heading-->
          <!-- <template v-for="(item, i) in list"> -->
            <!--begin::Item -->
            <!-- <div
              class="d-flex align-items-center rounded p-5 gutter-b"
              v-bind:class="`bg-light-${item.type}`"
              v-bind:key="i"
            >
              <span
                class="svg-icon mr-5"
                v-bind:class="`svg-icon-${item.type}`"
              >
                <span class="svg-icon svg-icon-lg"> -->
                  <!--begin::Svg Icon-->
                  <!-- <inline-svg :src="item.svg" /> -->
                  <!--end::Svg Icon-->
                <!-- </span>
              </span>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                  href="#"
                  class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {{ item.title }}
                </a>
                <span class="text-muted font-size-sm">
                  {{ item.desc }}
                </span>
              </div>
              <span
                class="font-weight-bolder py-1 font-size-lg"
                v-bind:class="`text-${item.type}`"
              >
                {{ item.alt }}
              </span>
            </div> -->
            <!--end::Item -->
          <!-- </template> -->
        </div>
        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>

      <v-snackbar
      top
      :timeout=4000
      v-model="snackbar.show"
      color="#4CAF50"
  >
      {{ snackbar.text }}
      <v-btn
      color="black"
      text
      @click="snackbar.show = false"
      >
      Close
      </v-btn>
  </v-snackbar>   
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import {mapState} from 'vuex'
import { LOGOUT } from "@/core/services/store/auth.module";
import { UPDATE_SELLER } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {      
        acceptOrders:false,
        snackbar:{
          show:false,
          text:''
        },
      // list: [
      //   {
      //     title: "Another purpose persuade",
      //     desc: "Due in 2 Days",
      //     alt: "+28%",
      //     svg: "media/svg/icons/Home/Library.svg",
      //     type: "warning"
      //   },
      //   {
      //     title: "Would be to people",
      //     desc: "Due in 2 Days",
      //     alt: "+50%",
      //     svg: "media/svg/icons/Communication/Write.svg",
      //     type: "success"
      //   },
      //   {
      //     title: "Purpose would be to persuade",
      //     desc: "Due in 2 Days",
      //     alt: "-27%",
      //     svg: "media/svg/icons/Communication/Group-chat.svg",
      //     type: "danger"
      //   },
      //   {
      //     title: "The best product",
      //     desc: "Due in 2 Days",
      //     alt: "+8%",
      //     svg: "media/svg/icons/General/Attachment2.svg",
      //     type: "info"
      //   }
      // ]
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.acceptOrders=this.storeAcceptOrders
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    onAcceptOrdersChange(e){
      let newSeller={...this.seller, acceptOrders:e}
      this.$store.dispatch(UPDATE_SELLER, newSeller)
      .then(data=>{
        this.showSnackbar("Datos actualizados con éxito")
        this.acceptOrders=e
      })
    },
      showSnackbar(msg){
      this.snackbar.text=msg
      this.snackbar.show=true
    },
  },
  computed: {
    ...mapState({               
        loading: state => state.auth.loading,
        user: state => state.auth.user,
        seller: state => state.auth.user.seller,
        storeAcceptOrders: state => state.auth.user.seller.acceptOrders
                          
    }),
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    }
  }
};
</script>
<style lang="scss" scoped>

.logo{
  width: 100px;
}

.v-input__slot{
  justify-content: flex-end !important;    
}

</style>
